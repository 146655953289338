{
  "name": "production-automation",
  "version": "1.0.3",
  "description": "",
  "default": "dist/public/views/index.html",
  "scripts": {
    "build": "NODE_ENV=development npm run build:all",
    "build:all": "npm run build:frontend && npm run build:backend && npm run build:env",
    "build:frontend": "parcel build --dist-dir=./dist/public/views --public-url=/views ./views/index.html",
    "build:backend": "cpx \"./static/**/{.*,*}\" dist",
    "build:env": "node -e \"process.stdout.write((process.env.NODE_ENV || '') + '\\n')\" > ./dist/config/environment",
    "build:development": "NODE_ENV=development npm run build:all",
    "build:production": "NODE_ENV=production npm run build:all",
    "start": "NODE_ENV=development npm run build:env && concurrently --kill-others npm:watch:frontend npm:watch:backend npm:server",
    "start:production": "NODE_ENV=production npm run build:env && NODE_ENV=production concurrently --kill-others npm:watch:frontend:production npm:watch:backend npm:server",
    "watch:frontend": "parcel watch --dist-dir=./dist/public/views --public-url=/views ./views/index.html",
    "watch:frontend:production": "export NODE_ENV=production && parcel watch --dist-dir=./dist/public/views --public-url=/views ./views/index.html",
    "watch:backend": "npm run build:backend -- --watch",
    "dev": "node watch.js",
    "dev-ui": "node watch.js ui",
    "prepare": "cd static && composer i",
    "deploy": "bash -c ./deploy.sh",
    "build:old": "node watch.js build",
    "server": "cd dist/public && php -S localhost:4200 ../../router.php",
    "test": "echo \"Error: no test specified\" && exit 1"
  },
  "devDependencies": {
    "@parcel/transformer-sass": "^2.0.1",
    "@parcel/transformer-typescript-tsc": "^2.0.1",
    "autoprefixer": "^10.0.1",
    "bestzip": "^2.1.7",
    "concurrently": "^6.2.2",
    "cpx": "^1.5.0",
    "fs-extra": "^9.0.1",
    "internal-app-styles": "https://github.com/EGZOTech/internal-app-styles.git",
    "parcel": "^2.0.1",
    "process": "^0.11.10",
    "sass": "^1.27.0",
    "typescript": "^4.0.3"
  },
  "author": "",
  "license": "ISC",
  "dependencies": {
    "@types/gapi.auth2": "0.0.52",
    "@types/google-one-tap": "^1.2.2",
    "@types/qrcode": "^1.3.5",
    "@zxing/browser": "0.0.5",
    "@zxing/library": "^0.18.3",
    "bark-js": "^1.2.0",
    "base32-encode": "^1.1.1",
    "jspdf": "^2.4.0",
    "jszip": "^3.7.1",
    "ndef": "^0.2.0",
    "preact": "^10.5.4",
    "preact-router": "^3.2.1",
    "qrcode": "^1.4.4"
  },
  "browserslist": [
    "last 2 chrome versions"
  ]
}
